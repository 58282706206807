import axios from 'axios';
import memoizee from "memoizee";
import axiosRetry, { isNetworkError } from 'axios-retry';
import { deserializeJsonApi } from "@/utils/jsonApiDeserializer.js";
import { logErrorOnSentry } from "@/utils/errorReportingUtils.js";
import { tap } from 'lodash';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"][content]')?.content

const api = axios.create();

export const apiWithRetry = tap(axios.create(), _api => axiosRetry(_api, {
  retries: 3,
  retryCondition: (e) => isNetworkError(e),
  onMaxRetryTimesExceeded: (e, retryCount) => logErrorOnSentry(
    e, { component: "api", function: "apiWithRetry" }, { retryCount: retryCount }
  )
}));

export const objectToFormData = data => {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    const value = data[key];
    if (typeof value === 'object') {
      Object.keys(value).forEach(subKey => {
        const subValue = value[subKey];
        formData.append(`${key}[${subKey}]`, subValue);
      });
    } else {
      formData.append(key, value);
    }
  });
  return formData;
};

export const base64ToBlob = base64 => {
  return fetch(base64).then(res => res.blob());
};

export const base64ToFile = (base64, filename) => {
  return base64ToBlob(base64).then(blob => {
    return new File([blob], filename);
  });
};


export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const apiGet = async (...args) => {
  const { data } = await api.get(...args);
  return deserializeJsonApi(data);
};

export const cachedApiGet = memoizee(async (...args) => {
  const { data } = await api.get(...args);
  return deserializeJsonApi(data);
}, { promise: true });

export default api;
